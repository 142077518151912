import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import CircularLoaderEditable from "react-loader-progressbar";
import "./App.css";
// route setup
const Routing = React.lazy(() => import("./Components/Routing/Routing"));

function App() {
  return (
    <Suspense fallback={<CircularLoaderEditable />}>
      <BrowserRouter>
        <Routing />
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
